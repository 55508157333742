import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Button,
  AlertColor,
  Box,
  Typography
} from "@mui/material";

import {
  createPrescriptions,
} from '../lib/api/reservation';

import LayoutWithMenu from "./LayoutWithMenu";

import { styles as commonStyles } from './style/commonStyles';
import CustomDialog from "./parts/CustomDialog";
import FileUploader from './parts/FileUploader';
import { Snackbar } from './parts/Snackbar';
import {useAuthUserContext} from "../contexts/AuthUserContext";
import {ButtonCaption, Messages, FacilityTypeCode} from "../utils/Constants";
import { randomFileName } from "../utils/helper";
import {moveToChoiceReserve, MoveToChoiceReserveParam} from "../utils/choiceReserve";


const styles = {
  ...commonStyles,
  PrescriptionBoxStyle: {
    mt: 2,
    py: 1,
    border: 1,
    borderColor: 'lightGrey'
  },
  prescriptionSendButton: {
    width: '95%',
    height: 40,
    mx: 2,
    backgroundColor: 'orange'
  },
}

type LocationParam = {
  directId: string,
  relationReservations: Array<number>,
  onlyMedNoRx: boolean,
  onlyMedWithRx: boolean,
  pharmacyName: string
}

const PrescriptionRegister = () => {

  // 遷移パラメータ
  const navigation = useNavigate();
  const authUser = useAuthUserContext().authUser;

  const location = useLocation();
  const [params] = useState<LocationParam>(location.state)

  const [prescriptionFiles, setPrescriptionFiles] = useState<File[]>([]);
  const [prescriptionURLs, setPrescriptionURLs] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  // dialog用
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<string>('');
  const [isHiddenCancel, setIsHiddenCancel] = useState<boolean>(false);
  const [handleOnConfirm, setHandleOnConfirm] = useState<() => void>(() => {});

  useEffect(() => {
    // パラメータチェック:必要なパラメータが空の場合、薬局の選択から処理をやり直す
    if (!params) {
      setDialogContent("処理が正しく行われませんでした。最初からやり直してください")
      setIsHiddenCancel(true);
      setHandleOnConfirm(() => moveToPharmacyList)
      setIsDialogOpen(true);
      return;
    }
  },[])

  // 薬局一覧画面へ戻る
  const moveToPharmacyList = () => {
    navigation("/pharmacy");
  }

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  // 外部サイト遷移確認
  const openMoveConfirmDialog = () => {
    const message = `処方せんの登録後、${Messages.pharmacyReservationNotice}`;
    setDialogContent(message)
    setIsHiddenCancel(false);
    setHandleOnConfirm(() => sendPrescription)
    setIsDialogOpen(true);
  }

  // choiceRESERVE予約画面への遷移
  const moveToChoiceReserveSite = (prescriptionId: number) => {
    const moveToParam = {
      directId: params?.directId,
      userId: authUser?.id,
      facilityTypeCode: FacilityTypeCode.pharmacy,
      relationIds: params.relationReservations,
      onlyMedNoRx: params.onlyMedNoRx,
      onlyMedWithRx: params.onlyMedWithRx,
      prescriptionRecordId: prescriptionId
    } as MoveToChoiceReserveParam;
    moveToChoiceReserve(moveToParam);
  }

  // 処方せん情報登録実行
  const sendPrescription = async () => {
    try {
      const formData = new FormData();
      formData.append('prescription[user_id]', authUser?.id.toString() || '');
      // 複数画像の登録
      prescriptionFiles.map((file) => {
        formData.append('prescription[prescription_files][]', file, randomFileName(file.name));
      })

      // 処方せん送信実行
      const result = await createPrescriptions(formData)

      // 外部予約サイト遷移
      moveToChoiceReserveSite(result.data.id);

    } catch (error) {
      console.error(error);
      setSnackbarProps('error', '処方せんの登録に失敗しました', true);
    } finally {
      // 画像クリアはスナックバーの表示後に行う
      setPrescriptionFiles([]);
    }
  }

  return (
    <LayoutWithMenu pageTitle={`${params?.pharmacyName || ''} 処方せん登録`}>
        <Box sx={{pt: '2em', px: '1em'}} >
          <Typography>{`お手元の処方せんを登録してください。`}</Typography>
          <Typography>{"また、服薬指導の開始までに処方せんの原本の提出が必要です。薬局まで提出ください。"}</Typography>
        
          <Box sx={{px: '1em', pt: '3em', pb: '2em'}} >
            <FileUploader
              files={prescriptionFiles}
              setImages={setPrescriptionFiles}
              isSingleSelect={false}
              imagesURL={prescriptionURLs}
              setImagesURL={setPrescriptionURLs}
            />

            <Grid container pt={3}>
              <Grid item xs={12}>
                <Button
                    color="primary"
                    variant="contained"
                    disabled={prescriptionFiles.length === 0}
                    sx={styles.prescriptionSendButton}
                    onClick={() => openMoveConfirmDialog()}
                  >
                    { '予約する' }
                  </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Grid container pt={3}>
          <Grid item xs={12}>
            <Button
                color="secondary"
                variant="outlined"
                sx={styles.button}
                onClick={() => navigation(-1)}
              >
                {ButtonCaption.back}
              </Button>
          </Grid>
        </Grid>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />

      <CustomDialog
        isOpen={isDialogOpen}
        content={dialogContent}
        isHiddenCancel={isHiddenCancel}
        onConfirm={handleOnConfirm}
        onCancel={() => setIsDialogOpen(false)}
      />
    </LayoutWithMenu>
  )
}
export default PrescriptionRegister