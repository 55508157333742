import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

// node_modulesからコピーしたpublicディレクトリにあるpdf.worker.min.mjsを読み込む
// pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.mjs";
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
