import { useEffect, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  AlertColor,
  FormHelperText,
  Stack,
  Typography,
  Box,
} from '@mui/material';
import imageCompression from "browser-image-compression";
import { useNavigate } from 'react-router-dom';

import { styles } from './style/commonStyles'
import LayoutWithMenu from './LayoutWithMenu';
import FileUploader from './parts/FileUploader';
import { Snackbar } from './parts/Snackbar';
import { ButtonCaption } from "../utils/Constants";
import { getUserDetail, registerImages, deleteHicardImage } from '../lib/api/user';
import { useAuthUserContext } from '../contexts/AuthUserContext'
import {randomFileName} from "../utils/helper";

const InsuranceCard = () => {
  const authUser = useAuthUserContext().authUser
  const navigation = useNavigate();

  const [hiCardImage1, setHiCardImage1] = useState<File[]>([])
  const [hiCardImageURL1, setHiCardImageURL1] = useState<string[]>([])
  const [hiCardImage2, setHiCardImage2] = useState<File[]>([])
  const [hiCardImageURL2, setHiCardImageURL2] = useState<string[]>([])
  const [hiCardImage3, setHiCardImage3] = useState<File[]>([]);
  const [hiCardImageURL3, setHiCardImageURL3] = useState<string[]>([]);
  const [hiCardImage4, setHiCardImage4] = useState<File[]>([]);
  const [hiCardImageURL4, setHiCardImageURL4] = useState<string[]>([]);
  const [hiCardImage5, setHiCardImage5] = useState<File[]>([]);
  const [hiCardImageURL5, setHiCardImageURL5] = useState<string[]>([]);
  const [hiCardImage6, setHiCardImage6] = useState<File[]>([]);
  const [hiCardImageURL6, setHiCardImageURL6] = useState<string[]>([]);

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [severity, setSeverity] = useState<AlertColor>('success');

  // 初期表示
  useEffect(() => {
    getHiCardData()
  },[])

  // 健康保険証画像データ取得
  const getHiCardData = async () => {
    try {
      const response = await getUserDetail(authUser?.id.toString() || '')

      setHiCardImageURL1([response.data.hicardImage1?.url])
      setHiCardImageURL2([response.data.hicardImage2?.url])
      setHiCardImageURL3([response.data.hicardImage3?.url])
      setHiCardImageURL4([response.data.hicardImage4?.url])
      setHiCardImageURL5([response.data.hicardImage5?.url])
      setHiCardImageURL6([response.data.hicardImage6?.url])
      // プレビュー用画像のクリア
      setHiCardImage1([])
      setHiCardImage2([])
      setHiCardImage3([])
      setHiCardImage4([])
      setHiCardImage5([])
      setHiCardImage6([])

    } catch (error) {
      console.log(error)
    }
  }

  // 登録ボタン制御
  const isRegisterDisabled =
    hiCardImage1.length === 0 && hiCardImage2.length === 0
    && hiCardImage3.length === 0 && hiCardImage4.length === 0
    && hiCardImage5.length === 0 && hiCardImage6.length === 0

  // 登録処理
  const register = async () => {
    const compressOptions = { maxSizeMB: 3 } // 3MB以下に圧縮

    // ファイルを圧縮する関数
    const processFile = async (file: File | null) => {
      if (!file) return null;
      // 画像ファイルの場合は圧縮
      if (file.type.startsWith('image/')) {
        return await imageCompression(file, compressOptions);
      }
      return file;
    };

    const compressedHiCardImage1 = await processFile(hiCardImage1[0]);
    const compressedHiCardImage2 = await processFile(hiCardImage2[0]);
    const compressedHiCardImage3 = await processFile(hiCardImage3[0]);
    const compressedHiCardImage4 = await processFile(hiCardImage4[0]);
    const compressedHiCardImage5 = await processFile(hiCardImage5[0]);
    const compressedHiCardImage6 = await processFile(hiCardImage6[0]);

    const formData = new FormData();
    if (compressedHiCardImage1)
      formData.append('user[hicard_image1]', compressedHiCardImage1, randomFileName(hiCardImage1[0].name))
    if (compressedHiCardImage2)
      formData.append('user[hicard_image2]', compressedHiCardImage2, randomFileName(hiCardImage2[0].name))
    if (compressedHiCardImage3)
      formData.append('user[hicard_image3]', compressedHiCardImage3, randomFileName(hiCardImage3[0].name));
    if (compressedHiCardImage4)
      formData.append('user[hicard_image4]', compressedHiCardImage4, randomFileName(hiCardImage4[0].name));
    if (compressedHiCardImage5)
      formData.append('user[hicard_image5]', compressedHiCardImage5, randomFileName(hiCardImage5[0].name));
    if (compressedHiCardImage6)
      formData.append('user[hicard_image6]', compressedHiCardImage6, randomFileName(hiCardImage6[0].name));

    // APIへPOST
    try {
      await registerImages(authUser?.id.toString() || '', formData)
      await getHiCardData()
      setSnackbarProps('success', '保険証画像を登録しました', true)
    } catch (error) {
      setSnackbarProps('error', '保険証画像の登録に失敗しました', true)
    }
  }

  // Snackbar表示プロパティ設定
  const setSnackbarProps = (severity: AlertColor, message: string, isOpen: true) => {
    setSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(isOpen);
  }

  const handleDeleteFile = async (fileName: string, index: number) => {
    try {
      await deleteHicardImage(authUser?.id.toString() || '', fileName)

      const setHiCardImageURLFunctions = [
        setHiCardImageURL1,
        setHiCardImageURL2,
        setHiCardImageURL3,
        setHiCardImageURL4,
        setHiCardImageURL5,
        setHiCardImageURL6,
      ];
      if (index >= 1 && index <= setHiCardImageURLFunctions.length) {
        setHiCardImageURLFunctions[index - 1]([]);
      }

      setSnackbarProps('success', '保険証画像を削除しました', true)
    } catch (error) {
      console.log(error)
      setSnackbarProps('error', '保険証画像の削除に失敗しました', true)
    }
  }

  return (
    <LayoutWithMenu>
      <Typography sx={{ ...styles.TypographyPageTitle, mt: 5 }} align="center">
        保険証登録
      </Typography>
      {(!hiCardImageURL1[0] ||
        hiCardImageURL1[0] === "" ||
        hiCardImageURL2[0] === "") && (
        <FormHelperText sx={{ textAlign: "center", mb: 4 }} error={true}>
          健康保険証画像(表・裏)を登録してください
          <br />
          医療証等をお持ちの場合は、医療証も登録してください
          <br />
          登録がない場合、診療や服薬指導が受けられない場合があります
          <br />
        </FormHelperText>
      )}

      {/* 健康保険証の画像アップロードUI */}
      <Typography
        align="left"
        sx={{
          fontWeight: "bold",
          fontFamily: "Roboto",
          color: "#171C1F",
          fontSize: "18px",
        }}
      >
        保険証
      </Typography>
      <Grid container spacing={2}>
        {[1, 2].map((index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box
              sx={{
                pt: 3,
                fontWeight: "bold",
                fontFamily: "Roboto",
                color: "#171C1F",
                fontSize: "18px",
              }}
            >
              {index === 1 && "表"}
              {index === 2 && "裏"}
            </Box>
            <Box>
              <FileUploader
                files={eval(`hiCardImage${index}`)}
                setImages={eval(`setHiCardImage${index}`)}
                isSingleSelect={true}
                imagesURL={eval(`hiCardImageURL${index}`)}
                setImagesURL={eval(`setHiCardImageURL${index}`)}
                onDeleteFile2={handleDeleteFile}
                accept="image/*,.png,.jpg,.jpeg,.gif"
                buttonText={"ファイルを選択する"}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 2 }} />

      {/* その他(医療証等)の画像アップロードUI */}
      <Typography
        align="left"
        sx={{
          mb: 4,
          fontWeight: "bold",
          fontFamily: "Roboto",
          color: "#171C1F",
          fontSize: "18px",
        }}
      >
        その他
        <br />
        (医療証等)
      </Typography>
      <Grid container spacing={2}>
        {[3, 4, 5, 6].map((index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Box>
              <FileUploader
                files={eval(`hiCardImage${index}`)}
                setImages={eval(`setHiCardImage${index}`)}
                isSingleSelect={true}
                imagesURL={eval(`hiCardImageURL${index}`)}
                setImagesURL={eval(`setHiCardImageURL${index}`)}
                onDeleteFile2={handleDeleteFile}
                accept="image/*,.png,.jpg,.jpeg,.gif"
                buttonText={"ファイルを選択する"}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Box display="flex" justifyContent="center" alignItems="baseline" gap="1.5rem">
        {/* 戻るボタン */}
        <Button
          sx={[styles.buttonOutlined,{ width: "95%" }] }
          color="primary"
          variant="outlined"
          size="medium"
          onClick={() => navigation(-1)}
        >
          {ButtonCaption.back}
        </Button>
        <Stack spacing={2} alignItems="center" width="100%">
          <Button
            sx={{
              backgroundColor: "#87D1EA",
              boxShadow: "none",
              fontSize: { xs: "0.75rem", sm: "0.875rem" },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "#5cb9d3",
                boxShadow: "none",
              },
              width: "100%",
            }}
            size="medium"
            disabled={isRegisterDisabled}
            color="primary"
            variant="contained"
            onClick={register}
          >
            {ButtonCaption.register}
          </Button>
          {isRegisterDisabled && (
            <FormHelperText error={true}>
              {"変更する場合は表面または裏面の画像を選択してください"}
            </FormHelperText>
          )}
        </Stack>
      </Box>

      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
}
export default InsuranceCard
