import { useEffect, useState } from "react";
import { Snackbar } from "./parts/Snackbar";
import LayoutWithMenu from "./LayoutWithMenu";
import ImageModal from "./parts/ImageModal";
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { AlertColor, Grid, Button } from "@mui/material";

import { getReservationWithFacility } from "../lib/api/reservation";

import { Reservation } from "../interfaces/index";
import { useAuthUserContext } from "../contexts/AuthUserContext";
import { styles as commonStyles } from "./style/commonStyles";
import { formattedDatetime } from "../utils/helper";
import { ButtonCaption } from "../utils/Constants";
import mime from "mime";
import { PDFThumbnail } from "./atoms/PDFThumbnail";

const ReceiptDetail = () => {
  const authUser = useAuthUserContext().authUser;
  const location = useLocation();
  const navigation = useNavigate();
  const [params] = useState(location.state);
  const [reservation, setReservation] = useState<Reservation>();

  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
  const [snackbarMessage] = useState<string>("");
  const [severity] = useState<AlertColor>("success");

  // MIMEタイプ判定関数
  const isPdfFile = (url: string) => {
    const mimeType = mime.getType(url.split("?")[0]); // クエリパラメータを除去してMIMEタイプを判定
    return mimeType === "application/pdf";
  };

  useEffect(() => {
    getReservationListByUserData();
  }, [authUser]);

  // 予約情報取得
  const getReservationListByUserData = async () => {
    if (!authUser) {
      return;
    }

    try {
      const response = await getReservationWithFacility(
        params.reservation_id.toString(),
      );
      setReservation(response.data);
    } catch (error) {
      console.error("予約一覧の取得に失敗しました", error);
    }
  };

  // style定義
  const styles = {
    deleteIcon: {
      position: "absolute",
      top: 10,
      right: 0,
    },
    previewImageGrid: {
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      position: "relative",
    },
    ...commonStyles,
    GridContainer: {
      borderColor: "lightGrey",
      py: 1,
      fontSize: 16,
      textDecoration: "none",
      color: "#000000",
      paddingBottom: 5,
    },
    GridItem: {
      alignContent: "center",
    },
  };

  // Modal表示管理
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalImgSrc, setModalImgSrc] = useState<string>("");

  return (
    <LayoutWithMenu>
      <Typography
        sx={{ ...commonStyles.TypographyPageTitle, mt: 8, textAlign: "left" }}
      >
        診療明細
      </Typography>

      {reservation && (
        <Grid container sx={styles.GridContainer}>
          <Grid container item xs={7} sx={styles.GridItem}>
            <div>
              <p>
                予約：{" "}
                {reservation.reservationTime
                  ? formattedDatetime(reservation.reservationTime)
                  : ""}
              </p>
              <p>{reservation.facility.name}</p>
            </div>
          </Grid>

          {reservation.receiptFiles && reservation.receiptFiles.length > 0 ? (
            <Grid container spacing={2}>
              {reservation.receiptFiles.map((file, index) => (
                <Grid item xs={6} md={4} sx={styles.GridItem} key={index}>
                  <Box
                    onClick={() => {
                      if (isPdfFile(file.url)) {
                        window.open(file.url, "_blank"); // PDFは新しいウィンドウで開く
                      } else {
                        setModalImgSrc(file.url); // 画像はモーダルに表示
                        setModalOpen(true);
                      }
                    }}
                    sx={{
                      width: "100%",
                      height: "100%",
                      border: "1px solid #ddd",
                      overflow: "hidden",
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    {isPdfFile(file.url) ? (
                      <PDFThumbnail filePath={file.url} />
                    ) : (
                      <img
                        src={file.url}
                        alt={`診療明細 ${index + 1}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Grid container item xs={12} sx={styles.GridItem}>
              <p>診療明細はありません</p>
            </Grid>
          )}
        </Grid>
      )}

      {/* 戻るボタン */}
      <Grid container pt={3} justifyContent="center">
        <Grid item xs={5}>
          <Button
            sx={{
              color: "black",
              borderColor: "black",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "black",
              },
              width: "100%",
            }}
            color="secondary"
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            {ButtonCaption.back}
          </Button>
        </Grid>
      </Grid>

      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
      />
      <Snackbar
        isOpen={snackbarOpen}
        setOpen={setSnackbarOpen}
        message={snackbarMessage}
        severity={severity}
      />
    </LayoutWithMenu>
  );
};

export default ReceiptDetail;
