import LayoutWithMenu from "components/LayoutWithMenu"
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { User } from "../../interfaces";
import { getUserDetail } from '../../lib/api/user';
import ImageModal from "components/parts/ImageModal";
import { Grid, Button } from "@mui/material";
import { styles } from "../style/commonStyles";
import {ButtonCaption} from "../../utils/Constants";

const ReservedUserHicard = () => {
  const { state: { user_id }  } =  useLocation()
  const [user, setUser] = useState<User>();

  const [modalOpen, setModalOpen] = useState(false)
  const [modalImgSrc, setModalImgSrc] = useState('')

  // Modal表示内容セット
  const setModalContent = (url: string) => {
    setModalImgSrc(url)
    setModalOpen(true)
  }

  const navigation = useNavigate();

  useEffect(() => {
    getUserData();
  },[user_id])

    const getUserData = async () => {
      if(user_id === undefined) return
      try {
        const userResult = await getUserDetail(user_id)
        setUser(userResult.data)
      } catch (error) {
        console.log(error)
      }
    }

  return (
    <LayoutWithMenu pageTitle="保険証">
      <h3>患者名: {user?.name ? `${user.name} 様` : ""}</h3>

      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={4}>
          {user?.hicardImage1?.url && (
            <img
              src={user?.hicardImage1.url}
              alt={`${user.name} 様の保険証の画像1`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage1.url &&
                setModalContent(user?.hicardImage1.url)
              }

            />
          )}
        </Grid>
        <Grid item xs={4}>
          {user?.hicardImage2?.url && (
            <img
              src={user?.hicardImage2.url}
              alt={`${user.name} 様の保険証の画像2`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage2.url &&
                setModalContent(user?.hicardImage2.url)
              }
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {user?.hicardImage3?.url && (
            <img
              src={user?.hicardImage3.url}
              alt={`${user.name} 様の保険証の画像3`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage3.url &&
                setModalContent(user?.hicardImage3.url)
              }
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {user?.hicardImage4?.url && (
            <img
              src={user?.hicardImage4.url}
              alt={`${user.name} 様の保険証の画像4`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage4.url &&
                setModalContent(user?.hicardImage4.url)
              }
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {user?.hicardImage5?.url && (
            <img
              src={user?.hicardImage5.url}
              alt={`${user.name} 様の保険証の画像5`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage5.url &&
                setModalContent(user?.hicardImage5.url)
              }
            />
          )}
        </Grid>
        <Grid item xs={4}>
          {user?.hicardImage6?.url && (
            <img
              src={user?.hicardImage6.url}
              alt={`${user.name} 様の保険証の画像6`}
              style={{
                width: "150px",
                height: "150px",
                objectFit: "contain",
              }}
              onClick={() =>
                user?.hicardImage6.url &&
                setModalContent(user?.hicardImage6.url)
              }
            />
          )}
        </Grid>
      </Grid>
      <ImageModal
        open={modalOpen}
        setOpen={setModalOpen}
        imgSrc={modalImgSrc}
        copyable
      />
      {/* 戻るボタン */}
      <Grid container pt={3} justifyContent="center">
        <Grid item xs={4}>
          <Button
            sx={{
              color: "black",
              borderColor: "black",
              "&:hover": {
                backgroundColor: "transparent",
                borderColor: "black",
              },
              width: "100%",
            }}
            color="secondary"
            variant="outlined"
            onClick={() => navigation(-1)}
          >
            {ButtonCaption.back}
          </Button>
        </Grid>
      </Grid>
    </LayoutWithMenu>
  );
}

export default ReservedUserHicard
