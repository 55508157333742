import { Modal as BaseModal, Box, IconButton } from "@mui/material";
import { Button } from "@mui/material";
import { css, styled } from "@mui/system";
import clsx from "clsx";
import React, { useState, useRef, useEffect } from "react";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

type Props = {
	open: boolean;
	setOpen: (arg: boolean) => void;
	imgSrc: string;
	copyable?: boolean;
};

export default function ModalUnstyled(props: Props) {
	const handleClose = () => props.setOpen(false);

	const [rotationAngle, setRotationAngle] = useState<number>(0);
	const [imageStyles, setImageStyles] = useState<{
		width: string;
		height: string;
	}>({
		width: "100%",
		height: "100%",
	});

	const boxRef = useRef<HTMLDivElement>(null);
	const [copyClicked, setCopyClicked] = React.useState(false);

	React.useEffect(() => {
		window.addEventListener("afterprint", () => {
			setCopyClicked(false);
		});
		return window.removeEventListener("afterprint", () => {});
	}, []);

	const adjustImageSize = (angle: number) => {
		if (!boxRef.current) return;

		const box = boxRef.current;
		const boxWidth = box.clientWidth;
		const boxHeight = box.clientHeight;

		const isHorizon = rotationAngle % 180 === 0;

		const  newWidth = isHorizon ? boxHeight : boxWidth;
		const  newHeight = isHorizon ? boxWidth : boxHeight;

		setImageStyles({
		width: `${newWidth}px`,
		height: `${newHeight}px`,
		});
	};

	const rotateImage = (direction: "left" | "right") => {
		const newRotation = (rotationAngle + (direction === "right" ? 90 : -90));
		setRotationAngle(newRotation);

		adjustImageSize(newRotation);
	};

	useEffect(() => {
		if (props.open) {
			adjustImageSize(rotationAngle);
		} else {
			setRotationAngle(0);
		}
	}, [props.open]);

	return (
		<div>
			<Modal
				aria-labelledby="unstyled-modal-title"
				aria-describedby="unstyled-modal-description"
				open={props.open}
				onClose={handleClose}
				slots={{ backdrop: StyledBackdrop }}
			>
				<ModalContent
					sx={{
						width: "90vw",
						height: "90vh",
						display: "flex",
						flexDirection: "column",
					}}
				>
				<Box
					ref={boxRef}
					flex={1}
					sx={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					overflow: "hidden",
					}}
				>
					<img
						src={props.imgSrc}
						style={{
							...imageStyles,
							objectFit: "contain",
							transform: `rotate(${rotationAngle}deg)`,
							transition: "transform 0.3s, width 0.3s, height 0.3s",
						}}
						alt={`お薬手帳に登録されている画像のプレビュー`}
					/>
				</Box>
				{/* 回転ボタン */}
				<Box display="flex" justifyContent="center" gap={2} mt={2}>
					<IconButton
					size="large"
					aria-label="画像を左に回転"
					onClick={() => rotateImage("left")}
					sx={{
						border: "1px solid #f0f0f0",
					}}
					>
					<RotateLeftIcon />
					</IconButton>
					<IconButton
					size="large"
					aria-label="Rotate image right"
					onClick={() => rotateImage("right")}
					sx={{
						border: "1px solid #f0f0f0",
					}}
					>
					<RotateRightIcon />
					</IconButton>
				</Box>
				{props.copyable && !copyClicked && (
					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							setCopyClicked(true);
							setTimeout(() => {
								window.print();
							}, 100);
						}}
					>
						印刷する
					</Button>
				)}
				</ModalContent>
			</Modal>
		</div>
	);
}

const Backdrop = React.forwardRef<
	HTMLDivElement,
	{ open?: boolean; className: string }
>((props, ref) => {
	const { open, className, ...other } = props;
	return (
		<div
			className={clsx({ "base-Backdrop-open": open }, className)}
			ref={ref}
			{...other}
		/>
	);
});

const Modal = styled(BaseModal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const ModalContent = styled("div")(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border-radius: 8px;
    border: 1px solid ${theme.palette.mode === "dark" ? "#434D5B" : "#DAE2ED"};
    padding: 8px;
  `
);
